import {useEffect, useState} from 'react'

import {
  ApolloClient,
  NormalizedCacheObject,
  useQuery,
} from '@apollo/client';

import { CachePersistor } from 'apollo3-cache-persist';
import { LocalForageWrapper } from 'apollo3-cache-persist/lib/storageWrappers';
import localforage from 'localforage';

import ProcessExplorer from './components/process_explorer/ProcessExplorer';
import Dashboard from './pages/Dashboard';
import Profile from './pages/Profile';
import Admin from './pages/Admin';
import CreateProcess from './pages/CreateProcess';
import Process from './pages/Process';
import Teams from './pages/Teams';
import Support from './pages/Support';
import KPIAnalysis from './components/kpi_analysis/KPIAnalysis';
import Calculators from './components/calculators/Calculators';
import Import from './pages/Import';
import Model from './pages/Model';
import Media from './pages/Media';
import AI from './pages/AI';
import Analyses from './pages/Analyses';
import Settings from './pages/Settings';
import Lexicon from './pages/Lexicon';
import Report from './pages/Report';
import Processes from './pages/Processes';
import KPIs from './components/kpi_analysis/KPIs';

//import Landing from './pages/Landing';
import Modeling from './pages/Modeling';

import Topic from './pages/Topic';

import {HashRouter,Route,Routes} from "react-router-dom";
import {ProtectedRoute} from './components/ProtectedRoute';
import { ApolloProvider } from '@apollo/client';
import Login from './pages/Login';

//import client from './Apollo' 
import { cache, combinedLink } from './Apollo';

import SidebarPage from './components/SidebarPage';
import { Skeleton, Card } from 'antd';
import LoadProcessData from './components/LoadProcessData';

//import 'ag-grid-community/dist/styles/ag-grid.css';
//import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

function App() {

  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject>>();
  const [persistor, setPersistor] = useState<CachePersistor<NormalizedCacheObject>>();

  useEffect(() => {
    async function init() {

      const storage = localforage.createInstance({
        name: 'apollo-cache',
        storeName: 'keyvaluepairs',
      });
      
      let newPersistor = new CachePersistor({
        cache,
        storage: new LocalForageWrapper(storage),
        debug: false,
        trigger: 'write',
        maxSize: false,
      });
      await newPersistor.restore();
      setPersistor(newPersistor);
      setClient(
        new ApolloClient({
          link: combinedLink,
          cache,
        }),
      );
    }


    init().catch(console.error);
  }, []);

  if (!client) {
    return (
      <div>
        <Card><Skeleton active /></Card>
      </div>
    );
  }

  return (
    <div> 
      <ApolloProvider client={client}>

          <LoadProcessData />
        
          <HashRouter>
          <Routes>
            
            <Route path="/" element={ <Login/>  } /> 
            <Route path="/login" element={ <Login/>  } /> 
            <Route path="/ai" element={ <ProtectedRoute><SidebarPage><AI/> </SidebarPage></ProtectedRoute>} />
            <Route path="/analyses" element={ <ProtectedRoute><SidebarPage><Analyses/> </SidebarPage></ProtectedRoute>} />
            <Route path="/kpis" element={ <ProtectedRoute><SidebarPage><KPIs/> </SidebarPage></ProtectedRoute>} />
            <Route path="/settings" element={ <ProtectedRoute><SidebarPage><Settings/> </SidebarPage></ProtectedRoute>} />
            <Route path="/report" element={ <ProtectedRoute><SidebarPage><Report/> </SidebarPage></ProtectedRoute>} />
            
            <Route path="/lexicon" element={ <ProtectedRoute><SidebarPage><Lexicon/> </SidebarPage></ProtectedRoute>} />
            <Route path="/dashboard" element={ <ProtectedRoute><SidebarPage><Dashboard/></SidebarPage></ProtectedRoute>  } /> 
            <Route path="/collections" element={ <ProtectedRoute><SidebarPage><Teams/></SidebarPage></ProtectedRoute>  } /> 
            <Route path="/collections/collection/:id" element={ <ProtectedRoute><SidebarPage><Topic/> </SidebarPage></ProtectedRoute>} />
            *<Route path="/processes" element={ <ProtectedRoute><SidebarPage><Processes/></SidebarPage></ProtectedRoute>  } />
            <Route path="/processes/process/:id" element={ <ProtectedRoute><SidebarPage><Process/></SidebarPage></ProtectedRoute> } /> 
            <Route path="/collections/collection/:id2/process/:id" element={ <ProtectedRoute><SidebarPage><Process/></SidebarPage></ProtectedRoute> } /> 

            <Route path="/create-process" element={ <ProtectedRoute><SidebarPage><CreateProcess/></SidebarPage></ProtectedRoute> } />
            
            <Route path="/process-explorer/analysis/:analysisId" element={ <ProtectedRoute><SidebarPage><ProcessExplorer/></SidebarPage></ProtectedRoute> } />
            <Route path="/process-explorer" element={ <ProtectedRoute><SidebarPage><ProcessExplorer/></SidebarPage></ProtectedRoute> } />
            
            <Route path="/profile" element={ <ProtectedRoute><SidebarPage><Profile/></SidebarPage> </ProtectedRoute>} />
            <Route path="/admin" element={ <ProtectedRoute><SidebarPage><Admin/> </SidebarPage></ProtectedRoute>} />
            <Route path="/support" element={ <ProtectedRoute><SidebarPage><Support/> </SidebarPage></ProtectedRoute>} />
            <Route path="/kpi-analysis" element={ <ProtectedRoute><SidebarPage><KPIAnalysis/> </SidebarPage></ProtectedRoute>} />
            <Route path="/calculators" element={ <ProtectedRoute><SidebarPage><Calculators/> </SidebarPage></ProtectedRoute>} />
            <Route path="/import" element={ <ProtectedRoute><SidebarPage><Import/> </SidebarPage></ProtectedRoute>} />
            <Route path="/modeling" element={ <ProtectedRoute><SidebarPage><Modeling/> </SidebarPage></ProtectedRoute>} />
            <Route path="/model/:id" element={ <ProtectedRoute><SidebarPage><Model/> </SidebarPage></ProtectedRoute>} />
            <Route path="/media" element={ <ProtectedRoute><SidebarPage><Media/> </SidebarPage></ProtectedRoute>} />
            
                  
          </Routes>
          </HashRouter>
        
      </ApolloProvider>

    </div>

  );
}

//ReactDOM.render(<MainPage/>  
//  , document.querySelector('#root'));

export default App;